import { z } from 'zod';

import { BaseEntity } from './base-entity';

export enum AssetAlternativeMetadataType {
  IMAGE = 'image',
  IMAGE_THUMBNAIL = 'image-thumbnail',
  VIDEO = 'video',
  PROMPT_VIDEO = 'prompt-video',
  PDF_DOCUMENT = 'pdf-document',
  RECORDING_DATA = 'recording-data',
}

export interface ImageMetadata {
  type: AssetAlternativeMetadataType.IMAGE;
  width: number;
  height: number;
}

export type ThumbnailType = 'image' | 'gif';

export interface ImageThumbnailMetadata {
  type: AssetAlternativeMetadataType.IMAGE_THUMBNAIL;
  alternativeId: string;
  thumbnailFormat: ThumbnailType;
  width: number;
  height: number;
}

export enum ChapterStatus {
  PROCESSING = 'processing',
  PROCESSED = 'processed',
}

export interface VideoMetadataChapter {
  id: string;
  title: string;
  ranges: { start: number; end: number }[];
  index: number;
  status: ChapterStatus;
}

export interface VideoMetadata {
  type: AssetAlternativeMetadataType.VIDEO;
  duration: number;
  chapters: Array<VideoMetadataChapter>;
}

export enum AssetAlternativeStatus {
  ACTIVE = 'active',
}

export enum BaseAssetAlternativeType {
  ORIGINAL = 'original',
}

export enum ImageAssetAlternativeType {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum RecordingAssetAlternativeType {
  NO_SILENCE = 'no-silence',
  OVERLAYED = 'overlayed',
  HIGHLIGHT_REEL = 'highlight-reel',
}

export enum PdfDocumentAssetAlternativeType {
  PREVIEW = 'preview',
}

export type AssetAlternativeType =
  | BaseAssetAlternativeType
  | ImageAssetAlternativeType
  | RecordingAssetAlternativeType
  | PdfDocumentAssetAlternativeType;

export interface PromptVideoMetadata {
  type: AssetAlternativeMetadataType.PROMPT_VIDEO;
  chapterId: string;
  chapters: VideoMetadataChapter[];
}

export interface PdfDocumentMetadata {
  type: AssetAlternativeMetadataType.PDF_DOCUMENT;
  pageCount: number;
  /**
   * The number of actual content pages, excluding filler pages.
   */
  contentPageCount?: number;
  aclGroupTokenIds?: string[];
}

export interface RecordingDataMetadata {
  type: AssetAlternativeMetadataType.RECORDING_DATA;
  promptId: string;
}

export type AssetAlternativeMetadata =
  | ImageMetadata
  | ImageThumbnailMetadata
  | VideoMetadata
  | PromptVideoMetadata
  | PdfDocumentMetadata
  | RecordingDataMetadata
  | null;

export interface AssetAlternative extends BaseEntity {
  assetId: string;
  type: AssetAlternativeType;
  mimeType: string;
  metadata: AssetAlternativeMetadata;
  status: AssetAlternativeStatus;
  expiresOn?: Date;
}

export interface AssetAlternativeView extends AssetAlternative {
  url: string;
}

export const VideoMetadataChapterSchema = z.object({
  id: z.string(),
  title: z.string(),
  ranges: z.array(
    z.object({
      start: z.number(),
      end: z.number(),
    }),
  ),
  index: z.number(),
  status: z.nativeEnum(ChapterStatus),
});

export const AssetAlternativeMetadataSchema = z.nullable(
  z.discriminatedUnion('type', [
    z.object({
      type: z.literal(AssetAlternativeMetadataType.IMAGE),
      width: z.number(),
      height: z.number(),
    }),
    z.object({
      type: z.literal(AssetAlternativeMetadataType.IMAGE_THUMBNAIL),
      alternativeId: z.string(),
      thumbnailFormat: z.union([z.literal('image'), z.literal('gif')]),
      width: z.number(),
      height: z.number(),
    }),
    z.object({
      type: z.literal(AssetAlternativeMetadataType.VIDEO),
      duration: z.number(),
      chapters: z.array(VideoMetadataChapterSchema),
    }),
    z.object({
      type: z.literal(AssetAlternativeMetadataType.PROMPT_VIDEO),
      chapterId: z.string(),
      chapters: z.array(VideoMetadataChapterSchema),
    }),
    z.object({
      type: z.literal(AssetAlternativeMetadataType.PDF_DOCUMENT),
      pageCount: z.number(),
      contentPageCount: z.optional(z.number()),
      aclGroupTokenIds: z.optional(z.array(z.string())),
    }),
    z.object({
      type: z.literal(AssetAlternativeMetadataType.RECORDING_DATA),
      promptId: z.string(),
    }),
  ]),
);
