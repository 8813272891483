export interface InterviewSession {
  sessionId: string;
  promptId: string;
  imageId: string | null;
  recordingType: 'audio' | 'video';
  recorderUserRuid: string | null;
  recorderUserId: string | null;
  recorderPersonId: string | null;
  promptQuestion: string;
  nextChunkIndex: number;
  finished: boolean;
  paused: boolean;
  duration: number;
  promptSentTimestamp: number;
  createdAt: number;
}

export type CreateInterviewSessionData = Omit<
  InterviewSession,
  'nextChunkIndex' | 'finished' | 'duration' | 'promptSentTimestamp'
>;

export const INTERVIEW_SESSION_EXPIRATION_TIME_MS = 7 * 24 * 60 * 60 * 1000;

export interface InterviewSessionRepository {
  createSession(session: CreateInterviewSessionData): void;
  setFinished(sessionId: string): void;
  setPaused(sessionId: string, paused: boolean): void;
  setNextChunkIndex(sessionId: string, nextChunkIndex: number): void;
  setDuration(sessionId: string, duration: number): void;
  setPromptSentTimestamp(sessionId: string, promptSentTimestamp: number): void;

  deleteInterviewSession(sessionId: string): void;

  getInterviewSession(sessionId: string): InterviewSession | null;
  getInterviewSessionIds(): string[];

  getOldInterviewSessions(): string[];
}
