import { AclGroupRole } from '@remento/types/acl';
import { EntityType } from '@remento/types/entity';
import { NotFoundError, UnknownError } from '@remento/types/error';
import { PRE_RENEWAL_PHASE_DURATION, UserSubscriptionStatus } from '@remento/types/user';
import { notNull } from '@remento/utils/array/notNull';
import Cookies from 'js-cookie';

import { Services, USER_COOKIE_DOMAIN, USER_COOKIE_NAME } from '@/Services';

import {
  getAccountReactivatePath,
  getAccountSettingsPath,
  getBookOrderCoverDesignPath,
  getBookOrderStoriesPath,
  getOnboardingIntroPath,
  getProjectPromptsPath,
  getProjectSettingsPath,
  getSetupPerspectivePath,
  getStoriesPath,
} from '../paths';
import { ActiveRoute, PageErrorRedirect, PageRedirect, RoutePath, SignInPageRedirect } from '../types/routing.types';
import { isInApp, isNewSigninAttempt } from '../utils/app';

export async function validateDefaultRoute(route: ActiveRoute, services: Services): Promise<void> {
  const { userService, userCacheService, personCacheService, aclCacheService, bookCacheService, projectCacheService } =
    services;
  const user = await userService.getUser();

  switch (route.route) {
    case RoutePath.Checkout:
      // Other checkout routes use the validate empty route
      return;
    case RoutePath.NewProject:
    case RoutePath.CheckoutFreeDetails:
    case RoutePath.CheckoutFreeReview: {
      if (user === null) {
        throw new PageRedirect(RoutePath.Signin);
      }
      if (user.storytellers.length == 0) {
        throw new PageRedirect(RoutePath.Checkout);
      }
      await userCacheService.loadUserStorytellers();
      return;
    }
    case RoutePath.Onboarding:
      if (user === null) {
        throw new PageRedirect(RoutePath.OnboardingSignin);
      }
      if (user.availableSubscriptions.length === 0) {
        throw new PageRedirect('/');
      }
      throw new PageRedirect(getOnboardingIntroPath());
    case RoutePath.OnboardingSignin: {
      if (user !== null) {
        throw new PageRedirect(RoutePath.Onboarding);
      }
      return;
    }
    case RoutePath.OnboardingIntro: {
      if (user === null) {
        throw new PageRedirect(RoutePath.Signin);
      }
      return;
    }
    case RoutePath.LegacyOnboardingFinish:
      if (user === null) {
        throw new PageRedirect(RoutePath.Signin);
      }
      if (!user.availableSubscriptions.length) {
        throw new PageRedirect(RoutePath.Root);
      }
      return;
    case RoutePath.SetupPromptTypeSelection:
    case RoutePath.SetupTextPromptSelection:
    case RoutePath.SetupPromptsReview: {
      if (user === null) {
        throw new PageRedirect(RoutePath.Signin);
      }

      // Prevent setup flow when the project already have prompts
      const projectId = route.params.projectId;
      const prompts = projectId ? await projectCacheService.getProjectPrompts(projectId) : [];
      if (projectId && prompts.length > 0) {
        throw new PageRedirect(getSetupPerspectivePath(projectId));
      }

      return;
    }
    case RoutePath.SetupPerspective:
    case RoutePath.SetupCollaborators: {
      if (user === null) {
        throw new PageRedirect(RoutePath.Signin);
      }
      return;
    }
    case RoutePath.Root:
      if (user !== null) {
        const projects = await projectCacheService.getProjects();
        throw new PageRedirect(getProjectPromptsPath(projects[0]));
      }
      throw new PageRedirect('/signin');
    case RoutePath.InviteSignin:
    case RoutePath.Signin:
      if (user == null) {
        // This is to make sure that the cookie is always removed if there's no user.
        Cookies.remove(USER_COOKIE_NAME, {
          domain: USER_COOKIE_DOMAIN,
        });
        return;
      }

      if (await isNewSigninAttempt(route.path as RoutePath, route.search, services)) {
        return;
      }

      throw new PageRedirect('/');
    case RoutePath.ProjectSettings: {
      if (user !== null) {
        const projectId = route.params.projectId;
        if (!projectId) {
          throw new PageRedirect('/');
        }
        const groupMembersIds = await aclCacheService.getCurrentUserAclGroupMembers();
        const groupMembers = await Promise.all(
          groupMembersIds.map((groupMemberId) => aclCacheService.getAclGroupMember(groupMemberId)),
        );
        const firstProjectGroupMember = groupMembers
          .filter(notNull)
          .find(
            (groupMember) =>
              groupMember.groupType === EntityType.PROJECT && groupMember.groupMetadata.projectId === projectId,
          );

        if (
          firstProjectGroupMember == null ||
          (firstProjectGroupMember.role !== AclGroupRole.OWNER && firstProjectGroupMember.role !== AclGroupRole.ADMIN)
        ) {
          throw new PageRedirect(getStoriesPath(projectId), route.search);
        }
        return;
      }
      throw new SignInPageRedirect(route.path, route.search);
    }
    case RoutePath.Stories:
    case RoutePath.Story:
    case RoutePath.Questions:
    case RoutePath.AccountSettings: {
      if (user !== null) {
        return;
      }
      throw new SignInPageRedirect(route.path, route.search);
    }
    // We use these routes to redirect to the right page for the first storyteller.
    // Necessary so old url's will still work.
    case RoutePath.StoriesLegacyRedirect:
    case RoutePath.QuestionsLegacyRedirect:
    case RoutePath.ProjectSettingsLegacyRedirect: {
      if (user !== null) {
        const [projectId] = await services.projectCacheService.getProjects();
        // @todo: remove?
        // const [firstStoryteller] = await personCacheService.getStorytellerPeople();
        if (!projectId) {
          throw new PageRedirect('/');
        }
        throw new PageRedirect(route.path + '/' + projectId);
      }
      throw new SignInPageRedirect(route.path, route.search);
    }
    case RoutePath.ProjectStoriesRedirect: {
      if (user !== null) {
        const [projectId] = await services.projectCacheService.getProjects();
        if (!projectId) {
          throw new PageRedirect('/');
        }

        throw new PageRedirect(getStoriesPath(projectId), route.search);
      }
      throw new SignInPageRedirect(route.path, route.search);
    }
    case RoutePath.ProjectQuestionsRedirect: {
      if (user !== null) {
        const [projectId] = await services.projectCacheService.getProjects();
        if (!projectId) {
          throw new PageRedirect('/');
        }

        throw new PageRedirect(getProjectPromptsPath(projectId), route.search);
      }
      throw new SignInPageRedirect(route.path, route.search);
    }
    case RoutePath.ProjectSettingsRedirect: {
      if (user !== null) {
        const [projectId] = await services.projectCacheService.getProjects();
        if (!projectId) {
          throw new PageRedirect('/');
        }

        throw new PageRedirect(getProjectSettingsPath(projectId), route.search);
      }
      throw new SignInPageRedirect(route.path, route.search);
    }
    case RoutePath.StoryHighlightReel:
    case RoutePath.StoryStandalone: {
      const storyId = route.params.storyId;
      if (storyId == null) {
        // This will never happen
        throw new PageRedirect('/');
      }
      // The standalone story view and the legacy stories page share the same URl (/stories/:ID).
      // To keep supporting both, we need to check if a story exists with the provided id and
      // if not, then we should check if the id is from a storyteller and redirect to the new stories page.
      try {
        await services.storyCacheService.getStory(storyId);
      } catch (error) {
        if (error instanceof NotFoundError) {
          const projects = await projectCacheService.getProjectsFromRecipientPersonId(storyId);
          if (projects.length > 0) {
            throw new PageRedirect(getStoriesPath(projects[0]), route.search);
          }
        }
        throw error;
      }
      return;
    }
    case RoutePath.Invite:
      return;
    case RoutePath.AccountName: {
      if (user === null) {
        throw new PageRedirect('/');
      }
      const person = await personCacheService.getPerson(user.personId);
      if (person === null) {
        throw new PageRedirect('/');
      }
      if (person.name) {
        throw new PageRedirect('/');
      }
      return;
    }
    // Book order
    case RoutePath.BookOrderCoverDesign: {
      if (user == null) {
        throw new SignInPageRedirect(route.path, route.search);
      }
      return;
    }
    case RoutePath.BookOrderStories:
    case RoutePath.BookOrderPreview:
    case RoutePath.BookOrderQuantity:
    case RoutePath.BookOrderFinalize: {
      if (user == null) {
        throw new SignInPageRedirect(route.path, route.search);
      }

      const bookId = route.params.bookId;
      if (bookId == null) {
        // The only way this error could occur is if we change the URL parameter name and forget to update it here.
        throw new UnknownError('book-id-not-provided');
      }

      const book = await bookCacheService.getBook(bookId);
      if (book === null) {
        throw new NotFoundError('book-not-found', {
          origin: 'entity',
          entityType: EntityType.BOOK_TEMPLATE,
          entityId: route.params.bookId,
        });
      }

      if (book.title == null && book.color == null) {
        throw new PageRedirect(getBookOrderCoverDesignPath(route.params.projectId ?? '', book.id), route.search);
      }

      if (route.route === RoutePath.BookOrderStories) {
        return;
      }

      if (book.storyIds.length === 0) {
        throw new PageRedirect(getBookOrderStoriesPath(route.params.projectId ?? '', book.id), route.search);
      }

      if (route.route === RoutePath.BookOrderPreview) {
        return;
      }

      // If the pdf has not been generated and previewed yet, redirect the user to the story selection page
      if (book.pdfAssetId == null) {
        throw new PageRedirect(getBookOrderStoriesPath(route.params.projectId ?? '', book.id), route.search);
      }

      return;
    }
    case RoutePath.BookOrderComplete:
      return;
    // Book reorder
    case RoutePath.BookReorderPreview:
    case RoutePath.BookReorderQuantity:
    case RoutePath.BookReorderFinalize: {
      if (user == null) {
        throw new SignInPageRedirect(route.path, route.search);
      }
      return;
    }
    // Poll
    case RoutePath.Poll: {
      return;
    }
    // Questionnaire
    case RoutePath.Questionnaire:
    case RoutePath.QuestionnaireRoot: {
      return;
    }
    // Project recording
    case RoutePath.ProjectRecord:
    case RoutePath.ProjectRecordCustomize: {
      const projectId = route.params.projectId;
      if (projectId == null) {
        // This will never happen
        throw new PageRedirect('/');
      }
      await projectCacheService.loadProjectPromptsCatchupData(projectId);
      return;
    }
    // Renewal
    case RoutePath.AccountRenewPromo: {
      if (user?.subscription?.status != UserSubscriptionStatus.ACTIVE) {
        throw new PageRedirect(getAccountReactivatePath());
      }
      if (user.subscription.endsOn - Date.now() > PRE_RENEWAL_PHASE_DURATION) {
        throw new PageRedirect('/');
      }

      return;
    }
    case RoutePath.AccountReactivate: {
      if (user?.subscription?.status !== UserSubscriptionStatus.INACTIVE) {
        throw new PageRedirect(getAccountSettingsPath(true, true));
      }
      return;
    }
    case RoutePath.InApp:
      if (!isInApp()) {
        throw new PageRedirect('/signin');
      }
      return;
    default:
      throw new PageErrorRedirect();
  }
}
